<template>
  <div class="textract-mp-wrapper">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="form-header">
      <VasionInput id="textract-aip-name" v-model="fieldsData.textractAIPName" title="Amazon Textract AIP Name"
        name="textract-aip-name" placeholder="Amazon Textract AIP Name..." inputType="top-white" />
      <div class="btns-container">
        <VasionButton id="cancel-button" name="cancel-button" :classProp="'secondary'" @vasionButtonClicked="cancel">
          Cancel
        </VasionButton>
        <VasionButton id="save-button" name="save-button" :classProp="'primary'" @vasionButtonClicked="save">
          Save
        </VasionButton>
      </div>
    </div>
    <div class="form-body row">
      <div class="col fields">
        <div class="fields-container" :class="{ 'w-100': showPreview }">
          <div class="form-row">
            <VasionInput v-model="fieldsData.originalSampleFilename" title="Sample File" inputType="top-white" name="file-name"
              placeholder="Select File..." :width="'100%'" :isDisabled="true" />
            <div>
              <VasionButton id="browse-file-for-sample" :classProp="'primary'" class="btn-margin-fix"
                @vasionButtonClicked="showFileUploader = true">
                Browse
              </VasionButton>
              <VasionButton id="toggle-preview" name="toggle-preview-button" :classProp="'primary'"
                @vasionButtonClicked="togglePreview" :isDisabled="!sampleData.length">
                Preview
              </VasionButton>
            </div>
          </div>
          <div class="vasion-flex-row">
            <div class="radio-container">
              <md-radio
                id="mapping-radio"
                v-model="fieldsData.processingOrder"
                value="1"
              >
                Mapping Order
              </md-radio>
            </div>
            <div class="radio-container">
              <md-radio
                id="confidence-radio"
                v-model="fieldsData.processingOrder"
                value="2"
              >
                Confidence Level
              </md-radio>
            </div>
          </div>
          <div class="vasion-flex-row">
            <VasionButton id="show-fail-logic-configuration" name="show-fail-logic-configuration" :classProp="'primary'"
              @vasionButtonClicked="customFailLogic = !customFailLogic" title="Fail logic is defaulted to fail fields on medium confidence.">
              {{customFailLogic ? 'hide' : 'show'}} fail logic
            </VasionButton>
            
          </div>
          <div class="vasion-flex-row line-item-data">
            <VasionCheckbox
              v-if="textractLineItemDataVisible"              
              id="line-item-data-checkbox"
              name="line-item-data-checkbox"
              class="row-checkbox line-item-data-checkbox"
              :checked="fieldsData.includeLineItemData"
              @change="fieldsData.includeLineItemData = !fieldsData.includeLineItemData"
            >
            Include line item data
            </VasionCheckbox>
            <div v-if="fieldsData.includeLineItemData && customFailLogic" class="line-item-failure-logic">
              <label class="fail-logic-label">Line item fail logic: </label>
              <md-radio
                id="ignore-radio"
                v-model="fieldsData.lineItemDataFailureLogic"
                value="1"
              >
                Ignore
              </md-radio>
              <md-radio
                id="low-radio"
                v-model="fieldsData.lineItemDataFailureLogic"
                value="2"
              >
                Low
              </md-radio>
              <md-radio
                id="medium-radio"
                v-model="fieldsData.lineItemDataFailureLogic"
                value="3"
              >
                Medium
              </md-radio>
            </div>
          </div>
          <div  class="d-flex">
            <h2 class="col-title">Redact</h2>
            <div class="w76" />
            <div v-if="customFailLogic" class="checkbox-group vasion-flex-row vasion-flex-vertical-center">
              <VasionCheckbox
                id="general-ignore"
                class="checkbox"
                name="general-ignore"
                :checked="generalIgnore"
                @change="failureLogicGeneralChange('ignore')"
              >
                Ignore
              </VasionCheckbox>
              <VasionCheckbox
                id="general-low"
                class="checkbox"
                name="general-low"
                :checked="generalLow"
                @change="failureLogicGeneralChange('low')"
              >
                Low
              </VasionCheckbox>
              <VasionCheckbox
                id="general-medium"
                class="checkbox"
                name="general-medium"
                :checked="generalMedium"
                @change="failureLogicGeneralChange('medium')"
              >
                Medium
              </VasionCheckbox>
            </div>
          </div>
          <div v-for="(field, index) in fieldsForMappging" :key="index+dropDownListsKey" class="d-flex">
            <div class="checkbox-redact vasion-flex-row vasion-flex-vertical-center">
              <VasionCheckbox
                :id="`redact-${field.id}`"
                class="checkbox"
                :name="`redact-${field.id}`"
                
                :checked="fieldsToRedact[field.id]"
                @change="redactchange(field.id)"
              />
            </div>
            <VasionDropList
              v-slot="slotItem"
              v-model="mappings[field.id]"
              :allowOutline="showPreview"
              :dataArray="sampleData"
              :showIfEmpty="true"
              :title="field.title"
              :name="`mappgin-field${field.id}`"
              class="w76"
              width="100%"
              valueName="calculatedFieldName"
              displayName="FieldName"
              placeholder="Select a Field..."
              type="check-list"
              :isDisabled="!sampleData.length"
              :pillOptions="true"
              :draggableOptions="true"
              @chipClick="onDropdownClick"
              @input="fieldChange($event, field.id)"
            >
              {{ slotItem.item.calculatedFieldName }}
            </VasionDropList>
            <div v-if="customFailLogic" class="checkbox-group vasion-flex-row vasion-flex-vertical-center">
              <VasionCheckbox
                :id="`ignore-${field.id}`"
                class="checkbox"
                :name="`ignore-${field.id}`"
                :checked="ignores[field.id]"
                @change="failureLogicChange('ignore', field.id)"
              >
                Ignore
              </VasionCheckbox>

              <VasionCheckbox
                :id="`low-${field.id}`"
                class="checkbox"
                :name="`low-${field.id}`"
                :checked="lows[field.id]"
                @change="failureLogicChange('low', field.id)"
              >
                Low
              </VasionCheckbox>

              <VasionCheckbox
                :id="`medium-${field.id}`"
                class="checkbox"
                :name="`medium-${field.id}`"
                :checked="mediums[field.id]"
                @change="failureLogicChange('medium', field.id)"
              >
                Medium
              </VasionCheckbox>
            </div>
          </div>          
        </div>
      </div>
      <div class="col preview" :class="{ 'd-block': showPreview }">
        <div class="preview-container">
          <DocumentImage
            ref="mainImage"
            :documentID="0"
            :key="documentImageKey"
            :pageNumber="currentPageNumber"
            :width="viewerWidth"
            :height="viewerHeight"
            :filePath="filePath"
            :largeDefaultLoadSize="true"
            :includeProcessedAIPZones="true"
            :context="'AnnotationsAndAIPZones'"
            :aipZones="aipZones"
            :disableMoveAndSaveAnnotations="true"
            :selectedAIP="''"
            :imageEndpoint="'document/getTextractDocumentImageURL'"
            @backgroundImageLoaded="backgroundImageLoaded"
          />
        </div>
        <div class="preview-toolbar">
          <VasionPager
            :currentPageNumber="currentPageNumber"
            :showPageSize="false"
            :totalPages="pageCount"
            @pageChanged="goToPage"
          />
          <div class="toolbar-divider"></div>
          <VasionZoomTool />
        </div>
      </div>
    </div>
    <md-dialog id="file-uploader" :md-click-outside-to-close="false" :md-active.sync="showFileUploader"
      class="file-uploader">
      <StartWorkflowFileUpload
        :key="fileUploaderKey"
        headerText="Select Import File"
        :isWorkflow="false"
        :handleFileInTheParent="true"
        :showDirectScan="false"
        :showBackButton="false"
        @cancelButtonClick="showFileUploader = false"
        @continueToAttributeForm="continueFromFileUploader"
      />
    </md-dialog>
    <VasionSnackbar
      id="snackbar-textract-mapping"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { getMeanConfidenceColor } from '@/enums/objectFieldMeanConfidenceLevels.js'
import StartWorkflowFileUpload from '@/components/workflow/StartWorkflowFileUpload.vue'
import DocumentImage from '@/components/document/DocumentImage.vue';
import VasionZoomTool from '@/components/shared/VasionZoomTool.vue';
import { isEmpty, find, capitalize, union } from 'lodash'

export default {
  name: 'TextractMappingPage',
  components: {
    DocumentImage,
    Loading,
    StartWorkflowFileUpload,
    VasionZoomTool,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      aipZones: {},
      currentPageNumber: 1,
      customFailLogic: false,
      dropDownListsKey: 1,
      documentImageKey: 0,
      fieldsData: {
        textractAIPName: this.data?.textractAIPName || '',
        mappings: {},
        sampleData: [],
        filename: this.data?.mappingData?.filename || '',
        originalSampleFilename: this.data?.mappingData?.originalSampleFilename || '',
        processingOrder: '1',
        includeLineItemData: false,
        lineItemDataFailureLogic: '3'
      },
      fieldsForMappging: {},
      fieldsToRedact: {},
      filePath: this.data?.mappingData?.filename || '',
      fileUploaderKey: 0,
      generalIgnore: false,
      generalLow: false,
      generalMedium: false,
      ignores: {},
      id: 0,      
      isLoading: false,
      textractLineItemDataVisible: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      lows: {},
      mappedTextractNames: [],
      mappings: {},
      mediums: {},
      pageCount: this.data.pageCount,
      processSampleData: {
        accessKey: this.data?.awsAccessKeyID || '',
        secretKey: this.data?.awsSecretKey || '',
        region: this.data?.awsRegion?.value || '',
        bucket: this.data?.awsS3Bucket?.value || '',
        textractType: this.data?.textractAPI?.value || 1,
      },
      sampleData: [],
      showFileUploader: false,
      showPreview: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      viewerHeight: 2112,
      viewerWidth: 1632,
    }
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch('attributeForm/getFormDetails', this.data.object.value)

    if (isEmpty(this.data.mappingData.mappings)) {
      this.fieldsForMappging = await this.getFieldsForMappging(this.$store.state?.attributeForm?.activeIndexForm?.fields)
      this.isLoading = false
      return
    }

    this.mappedTextractNames = await this.getMappedTextractNames(this.data.mappingData.mappings)
    this.fieldsData.sampleData = this.data.mappingData.sampleData
    let sampleData = this.data.mappingData.sampleData ? this.data.mappingData.sampleData : []
    this.sampleData = await this.setColorsOfSampleData(sampleData)
    this.fieldsData.originalSampleFilename = this.data.mappingData.originalSampleFilename
    this.fieldsForMappging = await this.getFieldsForMappging(this.$store.state?.attributeForm?.activeIndexForm?.fields)

    this.mappings = await this.setMappedFields()

    const { lows, mediums, ignores} = await this.setFailureLogicArrays()
    this.lows = lows
    this.mediums = mediums
    this.ignores = ignores
    
    this.fieldsToRedact = await this.setFieldsToRedact()

    this.fieldsData.processingOrder = this.data.mappingData.processingOrder

    this.fieldsData.includeLineItemData = this.data.includeLineItemData

    this.fieldsData.lineItemDataFailureLogic = this.data.lineItemDataFailureLogic

    this.textractLineItemDataVisible = this.$store.state.systemSettings.featureFlags.textractLineItemDataEnabled
    
    this.isLoading = false
  },
  methods: {
    async addMissingFieldsToSampleData(sampleData, fieldNames) {
      let newElements = []
      for (let index = 0; index < fieldNames.length; index++) {
        const element = fieldNames[index];
        const elementInSampleData = Boolean(find(sampleData, ['FieldName', element]))
        if (!elementInSampleData) {
          const newElement = {
            FieldName: element,
            itemClass: 'secondary-border',
            dotClass: 'secondary-dot',
            tooltipText: `${element} (Field not present in the current sample file.)`,
            calculatedFieldName: `${element} (Field not present in the current sample file.)`,
            itemNotPresent: true
          }
          newElements.push(newElement)
        }
      }
      return [...sampleData, ...newElements]
    },
    async backgroundImageLoaded(){
      await this.$store.dispatch('document/resetZoomValue')
      if (window.innerWidth <= 416) {
        this.$store.dispatch('document/setZoomValue', .2)
      } else if (window.innerWidth <= 1430) {
        this.$store.dispatch('document/setZoomValue', .3)
      } else if (window.innerWidth <= 1630) {
        this.$store.dispatch('document/setZoomValue', .4)
      } else if (window.innerWidth <= 1200) {
        this.$store.dispatch('document/setZoomValue', .42)
      } else {
        this.$store.dispatch('document/setZoomValue', .46)
      }
    },
    cancel() {
      this.$emit('cancel')
      this.$store.dispatch('capture/setSelectedOption', null)
    },
    async continueFromFileUploader(file) {
      this.isLoading = true
      if (!file.name.match(/.(pdf|tiff)$/i)) {
        this.snackbarTitle = 'Upload error'
        this.snackbarSubTitle = 'Only .pdf or .tiff file types are supported'
        this.snackbarImage = false
        this.showSnackbar = true
        this.isLoading = false
        return
      }
      
      const payload = { ...this.processSampleData, ...file.file, textractAIPId: this.data.id, filename: file.name }
      this.fileUploaderKey += 1
      this.showFileUploader = false
      const result = await this.$store.dispatch('document/processSampleTextractFile', payload)
      let sampleData = result.textractDatas != null ? result.textractDatas : []
      this.sampleData = await this.setColorsOfSampleData(sampleData)
      this.fieldsData.sampleData = result.sampleData
      this.fieldsData.filename = result.tempFileName
      this.fieldsData.originalSampleFilename = file.name
      this.dropDownListsKey += 1
      this.filePath = result.tempFileName
      this.pageCount = result.pageCount
      this.documentImageKey++
      this.mappings = await this.setMappedFields()
      this.aipZones = {}
      this.isLoading = false
    },
    failureLogicChange(type, fieldId) {
      let arrayOfCheckboxesToBeTurnedOff = ['ignores', 'lows', 'mediums']
      const currentTypeIndex = arrayOfCheckboxesToBeTurnedOff.indexOf(`${type}s`)
      if (currentTypeIndex === -1) return
      const currentType = arrayOfCheckboxesToBeTurnedOff[currentTypeIndex]
      arrayOfCheckboxesToBeTurnedOff.splice(currentTypeIndex, 1)

      if (!this.mappings[fieldId]?.length) return

      this[currentType][fieldId] = !this[currentType][fieldId]

      arrayOfCheckboxesToBeTurnedOff.forEach(element => {
        this[element][fieldId] = false
      });

      if (this.generalIgnore) this.generalIgnore = false
      if (this.generalLow) this.generalLow = false
      if (this.generalMedium) this.generalMedium = false
    },
    failureLogicGeneralChange(type) {
      let arrayOfCheckboxesToBeTurnedOff = ['ignore', 'low', 'medium']
      const currentTypeIndex = arrayOfCheckboxesToBeTurnedOff.indexOf(`${type}`)
      if (currentTypeIndex === -1) return
      const currentType = arrayOfCheckboxesToBeTurnedOff[currentTypeIndex]
      arrayOfCheckboxesToBeTurnedOff.splice(currentTypeIndex, 1)

      this[`general${capitalize(currentType)}`] = !this[`general${capitalize(currentType)}`]
      arrayOfCheckboxesToBeTurnedOff.forEach(element => {
        this[`general${capitalize(element)}`] = false
      })

      const failureLogicType = `${currentType}s`

      for (const key in this[failureLogicType]) {
        if (this.mappings[key]?.length) {
          this[failureLogicType][key] = this[`general${capitalize(currentType)}`]
        }
      }

      arrayOfCheckboxesToBeTurnedOff.forEach(element => {
        for (const key in this[`${element}s`]) {
          if (this.mappings[key]?.length) {
            this[`${element}s`][key] = false
          }
        }
      })
    },
    fieldChange(value, fieldId) {
      if (value.length > 0) return

      this.ignores[fieldId] = false
      this.lows[fieldId] = false
      this.mediums[fieldId] = false
      this.fieldsToRedact[fieldId] = false
    },
    async getFieldsForMappging(fields) {
      let fieldsForMappging = {}
      if (!fields) {
        this.snackbarTitle = "Object Fields Not Found"
        this.snackbarSubTitle = `Fields not related to the chosen object`
        this.snackbarImage = false
        this.showSnackbar = true
        return fieldsForMappging
      }

      const fieldNamesToIgnore = ['Workflow_Status', 'Workflow_Approver', 'Workflow_Due_Date', 'Workflow_Initiator', 'CreatedBy']

      fields.filter( field => !(fieldNamesToIgnore.includes(field.name))).map( field => {
        fieldsForMappging[field.fieldID] = {
          id: field.fieldID,
          name: field.name,
          title: field.title,
        }
      })
      return fieldsForMappging
    },
    async getMappedTextractNames(mappedFields) {
      let textractNames = []
      for (const key in mappedFields) {
        const element = mappedFields[key]
        textractNames = union(textractNames, element.TextractNames)
      }
      return textractNames
    },
    getMappings() {
      let mappings = []
      for (const key in this.mappings) {
        if (Object.hasOwnProperty.call(this.mappings, key)) {
          const element = this.mappings[key]
          let mapping = {}
          mapping.FieldId = Number(key)
          mapping.TextractNames = element.map(field => {
            return field.FieldName
          })
          mapping.FailureLogic = this.ignores[key] === true ? 1 : (this.lows[key] === true ? 2 : (this.mediums[key] === true ? 3 : null))
          mapping.RedactField = this.fieldsToRedact[key] === true ? true : false
          mappings.push(mapping)
        }
      }
      return mappings
    },
    getRGBColors(itemClass){
      let rgb = {r:108, g:117, b:125}
      switch (itemClass) {
        case "success-border":
          rgb = {r:0, g:182, b:76}
          break;
        case "warning-border":
          rgb = {r:255, g:171, b:0}
          break;
        case "error-border":
          rgb = {r:173, g:9, b:9}
          break;
        default:
          break;
      }
      return rgb
    },
    goToPage(pageNumber) {
      if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.pageCount) {
        this.currentPageNumber = pageNumber
        this.$store.dispatch('document/uploadedPagesPageNumber', this.currentPageNumber)
      }
    },
    onDropdownClick(item) {
      this.aipZones = {}
      if ('itemNotPresent' in item) return
      if (!this.showPreview) return
      if (item?.delete === true) {
        if (this.aipZones?.values && this.aipZones.values[0].UniqueID === `${item.FieldName}${item.Confidence}`) {
          this.aipZones = {}
        }
        return
      }
      let aipZones = {}
      const rgb = this.getRGBColors(item?.itemClass)
      aipZones.values = [{
        "fill": {
          "RECT_COLOR_R": rgb.r,
          "RECT_COLOR_G": rgb.g,
          "RECT_COLOR_B": rgb.b,
          "RECT_COLOR_A": "140",
          "disableSave": "True"
        },
        "ZoneHeight": item.PositionInInches.height,
        "ZoneWidth": item.PositionInInches.width,
        "ZoneX": item.PositionInInches.x,
        "ZoneY": item.PositionInInches.y,
        "Page": item.Page,
        "UniqueID": `${item.FieldName}${item.Confidence}`
      },]
      this.aipZones = aipZones
      if (this.currentPageNumber != item.Page) this.goToPage(item.Page)
    },
    redactchange(fieldId) {
      if (!this.mappings[fieldId]?.length) return
      this.fieldsToRedact[fieldId] = !this.fieldsToRedact[fieldId]
    },
    async save() {
      this.fieldsData.mappings = await this.getMappings()
      this.$emit('save', this.fieldsData)
      this.$store.dispatch('capture/setSelectedAIPZone', null)
    },
    async setColorsOfSampleData(sampleData) {
      const completeSampleData = await this.addMissingFieldsToSampleData(sampleData, this.mappedTextractNames)

      const sampleDataWithColors = completeSampleData.map(registry => {
        if ('itemClass' in registry && 'dotClass' in registry && 'tooltipText' in registry && 'calculatedFieldName' in registry) {
          return registry
        }

        const color = getMeanConfidenceColor(registry.Confidence)
        let colorCode = ''

        if (color === 'LawnGreen') {
          colorCode = 'success'
        } else if (color === 'Yellow') {
          colorCode = 'warning'
        } else if (color === 'Red') {
          colorCode = 'error'
        }

        return {
          ...registry,
          itemClass: `${colorCode}-border`,
          dotClass: `${colorCode}-dot`,
          tooltipText: `${registry.FieldName} (${Math.round(registry.Confidence)}% ${registry.Value})`,
          calculatedFieldName: `${registry.FieldName} (${Math.round(registry.Confidence)}% ${registry.Value})`
        }
      })
      return sampleDataWithColors
    },
    async setFailureLogicArrays() {
      let lows = {}, mediums = {}, ignores = {}

      for (const key in this.fieldsForMappging) {
        const element = this.fieldsForMappging[key]
        const mappedField = find(this.data.mappingData.mappings, ['FieldId', element.id])
        let failureLogicValue = null
        if (mappedField) {
          failureLogicValue = mappedField.FailureLogic
        }
        
        ignores[element.id] = failureLogicValue === 1 ? true : false
        lows[element.id] = failureLogicValue === 2 ? true : false
        mediums[element.id] = failureLogicValue === 3 ? true : false
      }

      return {lows, mediums, ignores}
    },
    async setFieldsToRedact() {
      let fieldsToRedact = {}
      for (const key in this.fieldsForMappging) {
        const element = this.fieldsForMappging[key]
        const mappedField = find(this.data.mappingData.mappings, ['FieldId', element.id])
        fieldsToRedact[element.id] = mappedField?.RedactField ? mappedField.RedactField : false
      }

      return fieldsToRedact
    },
    async setMappedFields() {
      let mappings = {}
      for (const key in this.fieldsForMappging) {
        if (Object.hasOwnProperty.call(this.fieldsForMappging, key)) {
          const element = this.fieldsForMappging[key]
          const mappedField = find(this.data.mappingData.mappings, ['FieldId', element.id])
          if (mappedField) {
            let selection = []
            for (const nameKey in mappedField.TextractNames) {
              if (Object.hasOwnProperty.call(mappedField.TextractNames, nameKey)) {
                const name = mappedField.TextractNames[nameKey]
                let sampleFieldSelected = find(this.sampleData, ['FieldName', String(name)])
                if (sampleFieldSelected) selection.push(sampleFieldSelected)
              }
            }
            if (selection) mappings[element.id] = selection
          }
        }
      }
      return mappings
    },
    togglePreview() {
      this.showPreview = !this.showPreview
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

.textract-mp-wrapper {
  width: 100%;
  height: 100%;
}

.form-header {
  display: block;
  height: 122px;
  width: 100%;
  box-shadow: 0 1px 0 0 $grey-100;
  background-color: $white;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  padding: 8px;
}

.btns-container {
  display: flex;
  align-items: center;
  justify-content: end;
}

::v-deep .btns-container #save-button>button {
  margin-right: 0px;
}

.form-body {
  width: 100%;
  height: calc(100vh - 122px);
  max-height: calc(100vh - 122px);
  padding: 8px 0px 8px 8px;
  margin: 0px;
  overflow-y: auto;
  // background-color: aquamarine;
}

.fields {
  // background-color: royalblue;
  height: calc(100vh - 95px);
  max-height: calc(100vh - 95px);
  overflow-y: auto;
}

.preview {
  // background-color: goldenrod;
  height: calc(100vh - 95px);
  max-height: calc(100vh - 95px);
  display: none;
}

.d-block {
  display: block;
}

.fields-container {
  width: 100%;
  // background-color: beige;
}

.w-100 {
  width: 100% !important;
}

div.form-row {
  padding: 6px;
  padding-left: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(296px, 1fr));
  grid-gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

#browse-file-for-sample {
  position: relative;
  top: 16px;
}

#toggle-preview {
  position: relative;
  top: 16px;
}

#file-uploader {
  z-index: 12;
}

.preview-container {
  height: calc(100vh - 174px);
  width: 100%;
  max-width: calc(50vw);
  background-color: #D9D9D9;
  align-items: center;
  padding-top: 2rem;
  padding-left: 2rem;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  overflow: auto;
}
.preview-toolbar {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .toolbar-divider {
    border-left: solid 1px $grey-100;
    height: 24px;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 10px;
  }
}

// sm: 576px
@media (min-width: $breakpoint-sm) {
  .preview-container {
    max-width: 90vw;
  }
}

// md: 768px
@media (min-width: $breakpoint-md) {
  .form-header {
    display: flex;
    height: 78px;
  }

  .form-body {
    height: calc(100vh - 78px);
    max-height: calc(100vh - 78px);
  }

  .fields {
    width: 50%;
  }

  .preview {
    width: 50% !important;
  }

  .preview-container {
    max-width: 90vw;
  }
}

// prevent side bar
@media (min-width: 950px) and (max-width: 991.98px) {
  .form-header {
    height: 78px;
  }

  .form-body {
    height: calc(100vh - 78px);
    max-height: calc(100vh - 78px);
  }

  .fields {
    width: 50%;
  }

  .preview {
    width: 50%;
  }
}

// lg: 992px
@media (min-width: $breakpoint-lg) {}

// xl: 1200px
@media (min-width: $breakpoint-xl) {}

// xxl: 1400px
@media (min-width: $breakpoint-xxl) {}

// Responsive Behaivr of Columns
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row>[class^=col] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  // background-color: rgba(86, 61, 124, .15);
  // border: 1px solid rgba(86, 61, 124, .2);
}

.col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: $breakpoint-md) {
  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .fields-container {
    width: 75%;
  }
}

.radio-container {
  width: 174px;
}

// Color Classes for VasionDropList
::v-deep .success-border {
  border-left: 4px solid #00B64C;
  margin-left: 8px;
}

::v-deep .warning-border {
  border-left: 4px solid #FFAB00;
  margin-left: 8px;
}

::v-deep .error-border {
  border-left: 4px solid #AD0909;
  margin-left: 8px;
}

::v-deep .secondary-border {
  border-left: 4px solid #6C757D;
  margin-left: 8px;
}

::v-deep .success-dot {
  height: 16px;
  width: 16px;
  background-color: #00B64C;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
  margin-left: 1px;
}

::v-deep .warning-dot {
  height: 16px;
  width: 16px;
  background-color: #FFAB00;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
  margin-left: 1px;
}

::v-deep .error-dot {
  height: 16px;
  width: 16px;
  background-color: #AD0909;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
  margin-left: 1px;
}

::v-deep .secondary-dot {
  height: 16px;
  width: 16px;
  background-color: #6C757D;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
  margin-left: 1px;
}

.mb-4px {
  margin-bottom: 4px;
}

.w76 {
  width: 76%;
}

.checkbox-group {
  margin-left: 8px;
  padding-top: 16px;
}

::v-deep .checkbox {
  margin-right: 24px;
}

::v-deep .checkbox svg {
  margin-right: unset;
}

.col-title {
  font-size: 14px;
  letter-spacing: .01em;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding-top: 19px;
  height: 40px;
}
.checkbox-redact {
  padding-top: 16px;
}

.line-item-data {
  min-height: 56px;
  display: flex;
  align-items: center;
}

::v-deep #show-fail-logic-configuration button {
  margin-left: 0px;
}

::v-deep .line-item-data-checkbox {
  font-size:14px;
}

.line-item-failure-logic {
  display: flex;
  align-items: center;
  padding-left: 50px;
}
::v-deep .line-item-failure-logic label {
  margin-bottom: 0px !important;
}

.fail-logic-label {
  font-family: $font-family-medium;
  font-size:14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $grey-500;
  padding-right: 10px;

}

::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
  color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
  border-color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
  background-color: $secondary !important;
}
</style>
