<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1></h1>
      <div class="btns-container">
        <VasionButton
            class="last-btn"
            :classProp="'secondary'"
            @vasionButtonClicked="cancel()"
          >
            Cancel
          </VasionButton>
          <VasionButton
            :classProp="'primary'"
            :isDisabled="!(isDirty && formReadyToBeSaved)"
            @vasionButtonClicked="save()"
          >
            Save
          </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <TextractForm
          ref="textractForm"
          :currentTextractData="currentTextractData"
          @markAsDirty="markAsDirty"
          @markFormStatus="markFormStatus"
          @markLoadingStatus="markLoadingStatus"
        />
    </div>
    <md-dialog
      id="modal-leave-confirmation"
      :md-active.sync="showLeaveDialog"
      :md-click-outside-to-close="false"
    >
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>
    <VasionSnackbar
      id="snackbar-textract-new"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig';
import Loading from 'vue-loading-overlay';
import TextractForm from '@/components/capture/TextractForm.vue';

export default {
  name: 'NewTextract',
  components: {
    Loading,
    TextractForm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.routeTo = to
      this.showLeaveDialog = true
    } else {
      this.routeTo = null
      next()
    }
  },
  data() {
    return {
      currentId: this.$route.params.AIPID,
      currentTextractData: {},
      formReadyToBeSaved: false,
      isDirty: false,
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      showLeaveDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    isEdit() {
      return this.currentId ? true : false
    }
  },
  created: async function () {
    if (this.isEdit) {
      await this.getSingleRegistry()
    }
  },
  methods: {
    cancel() {
      if (this.isDirty) {
        this.routeTo = null
        this.showLeaveDialog = true
      } else {
        this.$router.push({ name: 'AdvancedImageProcessing' })
      }
    },
    cancelLeave() {
      this.showLeaveDialog = false
    },
    doLeave() {
      this.isDirty = false
      this.showLeaveDialog = false
      if (this.routeTo) {
        this.$router.push({ path: this.routeTo.path })
      } else {
        this.$router.push({ name: 'AdvancedImageProcessing' })
      }
    },
    async getSingleRegistry() {
      this.isLoading = true
      const response = await this.$store.dispatch('admin/getTextractAIP', this.currentId)
      this.isLoading = false
      if (response && response?.Data) {
        this.currentTextractData = {...response.Data, pageCount: response.pageCount}
      } else if (response && response?.Value === "False") {
        this.snackbarTitle = "Amazon Textract AIP"
        this.snackbarSubTitle = response?.ResultMessage ? response?.ResultMessage : 'An error occurred while fetching data'
        this.snackbarImage = false
        this.showSnackbar = true
      } else {
        this.snackbarTitle = "Amazon Textract AIP"
        this.snackbarSubTitle = 'An error occurred while fetching data'
        this.snackbarImage = false
        this.showSnackbar = true
        setTimeout(() => {
          this.$router.push({ name: 'AdvancedImageProcessing' })
        }, 1000)
      }
    },
    markAsDirty() { this.isDirty = true },
    markFormStatus(status) { this.formReadyToBeSaved = status },
    markLoadingStatus(status) { this.isLoading = status },
    async save() {
      if (!this.formReadyToBeSaved) return

      this.isLoading = true
      const textractData = this.$refs.textractForm.formFieldsData;
      let payload = {
        "id": this.isEdit ? this.currentId : 0,
        "indexFormId": textractData.object ? textractData.object.value : 0,
        "name": textractData.textractAIPName,
        "textractAPI": textractData.textractAPI.value,
        "filename": textractData.mappingData.filename,
        "originalSampleFilename": textractData.mappingData.originalSampleFilename,
        "awsConfig": {
          "region": textractData.awsRegion.value,
          "bucketName": textractData.awsS3Bucket.value,
          "clientId": textractData.awsAccessKeyID,
          "clientsecret": textractData.awsSecretKey,
        },
        "mappings": textractData.textractAPI.displayName === "Detect" ? [] : textractData.mappingData.mappings,
        "sampleData": textractData.mappingData.sampleData,
        "processingOrder": textractData.mappingData.processingOrder,
        "ocr": textractData.ocr,
        "includeLineItemData":  textractData.includeLineItemData,
        "comprehend": textractData.comprehend,
        "lineItemDataFailureLogic": textractData.lineItemDataFailureLogic
      }

      if (textractData.comprehend) {
        payload.comprehendAnnotationType = textractData.comprehendAnnotationType
        payload.comprehendFieldID = textractData.comprehendFieldID.id
        payload.comprehendPIIData = textractData.comprehendPIIData.map(item => item['value'])
        payload.burnRedactions = textractData.burnRedactions
      }
      
      const response = await this.$store.dispatch('admin/saveTextractAIP', payload)
      this.isLoading = false
      if (response && response?.Value === "True") {
        this.s3BucketHaveAccess = true
        this.snackbarTitle = "Amazon Textract AIP"
        this.snackbarSubTitle = `Amazon Textract AIP Successfully Saved`
        this.snackbarImage = true
        this.showSnackbar = true
        this.isDirty = false
        setTimeout(() => {
          this.$router.push({ name: 'AdvancedImageProcessing' })
        }, 2000)
      } else {
        this.s3BucketHaveAccess = false
        this.snackbarTitle = "Amazon Textract AIP"
        this.snackbarSubTitle = response?.ResultMessage ? response?.ResultMessage : `An error occurred during save`
        this.snackbarImage = false
        this.showSnackbar = true
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

</style>
