<template>
  <div class="">
    <div class="form-row">
      <VasionInput
        id="textract-aip-name"
        v-model="formFieldsData.textractAIPName"
        title="Amazon Textract AIP Name"
        name="textract-aip-name"
        placeholder="Amazon Textract AIP Name..."
        inputType="top-white"
        required
        :autoFocus="true"
        @input="emitWrapperIsDirty"
      />
    </div>
    <div class="form-row">
      <VasionInput
        id="aws-access-key-id"
        v-model="formFieldsData.awsAccessKeyID"
        title="AWS Access Key ID"
        name="textract-aip-name"
        placeholder="AWS Access Key ID..."
        inputType="top-white"
        required
        @input="emitWrapperIsDirty"
      />
      <VasionInput
        id="aws-secret-key"
        v-model="formFieldsData.awsSecretKey"
        title="AWS Secret Key"
        name="aws-secret-key"
        type="password"
        placeholder="AWS Secret Key..."
        inputType="top-white"
        required
        preventPasswordAutofill
        @input="emitWrapperIsDirty"
      />
      <VasionButton
        :classProp="'primary'"
        :isDisabled="!aipNameAWSConnectionEmpty"
        class="btn-margin-fix"
        @vasionButtonClicked="validateAwsCredentials()"
      >
        Validate
      </VasionButton>
    </div>
    <div class="form-row">
      <VasionDropList
        v-slot="slotItem"
        v-model="formFieldsData.awsRegion"
        title="AWS Region"
        :dataArray="fieldList.awsRegionList"
        width="100%"
        :showSearchField="false"
        type="plain-list"
        displayName="displayName"
        valueName="value"
        :required="true"
        :isDisabled="!isAWSAccountValid"
        @input="emitWrapperIsDirty(); getS3Buckets();"
      >
        {{ slotItem.item.displayName }}
      </VasionDropList>
      <VasionDropList
        v-slot="slotItem"
        v-model="formFieldsData.awsS3Bucket"
        title="S3 Bucket"
        :dataArray="fieldList.awsS3BucketList"
        width="100%"
        :showSearchField="false"
        type="plain-list"
        displayName="displayName"
        valueName="value"
        :required="true"
        :isDisabled="!(awsRegionIsPresent && !isAWSS3BucketListEmpty)"
        @input="emitWrapperIsDirty"
      >
        {{ slotItem.item.displayName }}
      </VasionDropList>
      <VasionButton
        :classProp="'primary'"
        :isDisabled="!awsBucketDataIsPresent"
        class="btn-margin-fix"
        @vasionButtonClicked="validateS3BucketAccess()"
      >
        Validate
      </VasionButton>
    </div>
    <div class="form-row">
      <VasionDropList
        v-slot="slotItem"
        v-model="formFieldsData.textractAPI"
        title="Amazon Textract API"
        :dataArray="fieldList.textractAPIList"
        width="100%"
        :showSearchField="false"
        type="plain-list"
        displayName="displayName"
        valueName="value"
        :required="true"
        :isDisabled="!s3BucketHaveAccess"
        @input="setApiType"
      >
        {{ slotItem.item.displayName }}
      </VasionDropList>
      <VasionCheckbox
          v-if="ocrVisible"
          id="ocr-checkbox"
          name="ocr-checkbox"
          class="row-checkbox"
          :checked="formFieldsData.ocr"
          :isDisabled="detectSelected"
          @change="formFieldsData.ocr = !formFieldsData.ocr; emitWrapperIsDirty(); deleteComprehendConfig(); formFieldsData.comprehend = false;"
        >
          Include full text OCR data
        </VasionCheckbox>

    </div>
    <div class="form-row">
      <VasionDropList
        v-slot="slotItem"
        v-model="formFieldsData.object"
        title="Object"
        :dataArray="fieldList.objectList"
        width="100%"
        :showSearchField="true"
        type="plain-list"
        displayName="name"
        valueName="value"
        :required="true"
        :isDisabled="!textractAPISelected"
        @input="onObjectChanged"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
      <VasionButton
        :classProp="'primary'"
        :isDisabled="!mappingButtonActivated"
        class="btn-margin-fix"
        @vasionButtonClicked="showDialogMappingPage = true"
      >
        Map Field Data
      </VasionButton>
    </div>
    <div class="container" v-if="!lendingSelected">
      <VasionCheckbox
        id="comprehend-is-enabled"
        name="comprehend-is-enabled"
        class="checkbox"
        :checked="formFieldsData.comprehend"
        :isDisabled="!isComprehendCheckboxAvailable"
        @change="comprehendChanged"
        :title="comprehendTitle"
      >
        Amazon Comprehend PII detection
      </VasionCheckbox>
      <div v-if="formFieldsData.comprehend">
        <div class="form-row">
          <div>
            <md-radio
              v-model="formFieldsData.comprehendAnnotationType"
              @change="emitWrapperIsDirty"
              :value="1"
            >
              Highlight
            </md-radio>
            <md-radio
              v-model="formFieldsData.comprehendAnnotationType"
              @change="emitWrapperIsDirty"
              :value="2"
            >
              Redact
            </md-radio>
          </div>
          <VasionCheckbox
            id="burn-redactions"
            name="burn-redactions"
            class="mid-checkbox"
            :checked="formFieldsData.burnRedactions"
            @change="burnRedactionsChanged"
          >
            Burn to Document
          </VasionCheckbox>
        </div>
        <div class="form-row px-0">
          <VasionDropList
            v-slot="slotItem"
            v-model="formFieldsData.comprehendFieldID"
            title="Field to store PII result"
            width="100%"
            type="plain-list"
            displayName="title"
            valueName="id"
            :dataArray="indexFieldList"
            :showSearchField="false"
            :required="true"
            :showIfEmpty="true"
            @input="emitWrapperIsDirty"
          >
            {{ slotItem.item.title }}
          </VasionDropList>
          <VasionDropList
            v-slot="slotItem"
            v-model="formFieldsData.comprehendPIIData"
            title="Amazon Comprehend Types"
            width="100%"
            type="check-list"
            displayName="displayName"
            valueName="value"
            :dataArray="comprehendTypes"
            :showSearchField="true"
            :required="true"
            @input="emitWrapperIsDirty"
          >
            {{ slotItem.item.displayName }}
          </VasionDropList>
        </div>
        <VasionButton
          classProp="primary"
          class="btn-margin-fix"
          @vasionButtonClicked="validateAwsComprehendAccess()"
        >
          Validate Amazon Comprehend Access
        </VasionButton>
      </div>
    </div>
    <VasionSnackbar
      id="snackbar-textract-form"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
    <md-dialog :md-active.sync="showDialogMappingPage" :md-fullscreen="true" class="dialog-fullscreen" :md-click-outside-to-close="false">
      <TextractMappingPage
        :data="formFieldsData"
        @cancel="showDialogMappingPage = false"
        @save="saveMappingData"
      />
    </md-dialog>
    <md-dialog
      id="modal-object- change"
      :md-active.sync="showPreventObjectChangeDialog"
      :md-click-outside-to-close="false"
    >
      <VasionConfirmationDialog
        :message="`Are you sure you want to change the Object? The mapped ${formFieldsData.comprehend ? 'and Amazon Comprehend' : ''} configuration will be lost.`"
        @noButtonClick="cancelObjectChange()"
        @yesButtonClick="objectChangeConfirmation()"
      />
    </md-dialog>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import lists from './awsFieldsList.json';
import TextractMappingPage from './TextractMappingPage.vue';

export default {
  name: 'TextractForm',
  components: {
    TextractMappingPage,
  },
  props: {
    currentTextractData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      comprehendTypes: [],
      currentData: this.currentTextractData,
      fieldList: {
        awsRegionList: [{}],
        awsS3BucketList: [{}],
        objectList: [{}],
        textractAPIList: lists.textractAPIList,
      },
      formFieldsData: {
        awsAccessKeyID: '',
        awsRegion: {},
        awsS3Bucket: {},
        awsSecretKey: '',
        burnRedactions: false,
        comprehend: false,
        comprehendAnnotationType: 0,
        comprehendFieldID: null,
        comprehendPIIData: [],
        object: {},
        ocr: false,
        textractAIPName: '',
        textractAPI: {},
        mappingData: {
          processingOrder: '1',
        },
        pageCount: 0,
        id: 0,
        includeLineItemData: false,
        lineItemDataFailureLogic: '1'
      },
      isAWSAccountValid: false,
      isAWSS3BucketListEmpty: true,
      isFormValid: false,
      isMappingDataExits: false,
      lastSelectedObject: {},
      s3BucketHaveAccess: false,
      showDialogMappingPage: false,
      showPreventObjectChangeDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    aipNameAWSConnectionEmpty() {
      return Boolean(this.formFieldsData.textractAIPName && this.formFieldsData.awsAccessKeyID && this.formFieldsData.awsSecretKey)
    },
    awsBucketDataIsPresent() {
      return Boolean(this.formFieldsData.awsRegion?.value && this.formFieldsData.awsS3Bucket?.value)
    },
    awsRegionIsPresent() {
      return Boolean(this.formFieldsData.awsRegion?.value)
    },
    comprehendTitle() {
      return this.isComprehendCheckboxAvailable ? 'Enable Amazon Comprehend PII detection configuration' : 'Amazon Comprehend PII detection needs Include full text OCR data'
    },
    detectSelected() {
      return Boolean(this.textractAPISelected && this.formFieldsData.textractAPI?.displayName === 'Detect')
    },
    fieldsMapped() {
      return Boolean(!isEmpty(this.formFieldsData.mappingData?.mappings))
    },
    lendingSelected() {
      return Boolean(this.textractAPISelected && this.formFieldsData.textractAPI?.displayName === 'Lending')
    },
    indexFieldList() {
      let fieldsForStorePII = []
      const fieldNamesToIgnore = ['Workflow_Status', 'Workflow_Approver', 'Workflow_Due_Date', 'Workflow_Initiator', 'CreatedBy']
      const fields = this.$store.state.attributeForm.activeIndexForm.fields || []

      fields.filter(field => !(fieldNamesToIgnore.includes(field.name))).map(field => {
        fieldsForStorePII.push({
          id: field.fieldID,
          name: field.name,
          title: field.title,
        })
      })

      return fieldsForStorePII
    },
    isComprehendCheckboxAvailable() {
      return Boolean(this.formFieldsData.ocr)
    },
    isEdit() {
      return 'Id' in this.currentData
    },
    mappingButtonActivated() {
      return Boolean(this.aipNameAWSConnectionEmpty && this.awsBucketDataIsPresent && this.textractAPISelected && this.objectFieldSelected && !this.detectSelected)
    },
    objectFieldSelected() {
      return Boolean(this.formFieldsData.object?.value)
    },
    ocrVisible() {
      return Boolean(this.textractAPISelected && this.formFieldsData.textractAPI?.displayName !== 'Lending')
    },
    textractAPISelected() {
      return Boolean(this.formFieldsData.textractAPI?.value)
    },
  },
  watch: {
    async currentTextractData(newValue) {
      this.currentData = newValue
      if (isEmpty(this.fieldList.objectList)) this.fieldList.objectList = await this.$store.dispatch('attributeForm/getForms')
      if (this.isEdit && Object.keys(this.currentData).length) await this.setFormValues(this.currentData)
    },
    async fieldList(newValue) {
      if (newValue.objectList) {
        if (this.isEdit && Object.keys(this.currentData).length) await this.setFormValues(this.currentData)
      }
    }
  },
  async created() {
    this.fieldList.objectList = await this.$store.dispatch('attributeForm/getForms')
    await this.getComprehendTypes()
    await this.getS3Regions()
    if (this.isEdit && Object.keys(this.currentData).length) await this.setFormValues(this.currentData)
    if (this.isEdit) await this.getS3Buckets()
  },
  methods: {
    burnRedactionsChanged() {
      this.formFieldsData.burnRedactions = !this.formFieldsData.burnRedactions
      this.emitWrapperIsDirty()
    },
    cancelObjectChange() {
      this.formFieldsData.object = this.lastSelectedObject
      this.showPreventObjectChangeDialog = false
    },
    comprehendChanged() {
      this.formFieldsData.comprehend = !this.formFieldsData.comprehend
      this.emitWrapperIsDirty()

      if (!this.formFieldsData.comprehend) this.deleteComprehendConfig()
    },
    deleteComprehendConfig() {
      this.formFieldsData.comprehendAnnotationType = 0
      this.formFieldsData.comprehendFieldID = null
      this.formFieldsData.comprehendPIIData = []
      this.formFieldsData.burnRedactions = false
      this.emitWrapperIsDirty()
    },
    emitWrapperIsDirty() {
      const validForm = this.isTheFormValid()
      this.$emit('markAsDirty')
      this.$emit('markFormStatus', validForm)
    },
    async getComprehendTypes() {
      const response = await this.$store.dispatch('admin/getComprehendTypes')
      if (response && response?.Values) {
        let comprehendTypes = []
        await response.Values.forEach(type => comprehendTypes.push({ value: type, displayName: type }))
        this.comprehendTypes = comprehendTypes
      }
    },
    async getObjectFields(objectId) {
      await this.$store.dispatch('attributeForm/getFormDetails', objectId)
    },
    async getS3Regions() {
      this.$emit('markLoadingStatus', true)
      const response = await this.$store.dispatch('admin/getAwsRegions')
      this.$emit('markLoadingStatus', false)
      if(response && response?.data.Values){
        const regions = []
        await response.data.Values.forEach(region => regions.push({value: region, displayName: region}))
        this.fieldList.awsRegionList = regions
      }
    },
    async getS3Buckets() {
      if (!this.formFieldsData.awsRegion?.value) {
        this.formFieldsData.awsS3Bucket = [{}]
        return
      }
      
      this.$emit('markLoadingStatus', true)
      const payload = {
        ...(this.isEdit && {"id": this.currentData.Id}),
        "awsAccessKeyID": this.formFieldsData.awsAccessKeyID,
        "awsSecretKey": this.formFieldsData.awsSecretKey,
        "awsRegion": this.formFieldsData.awsRegion.value,
      }
      const response = await this.$store.dispatch('admin/listTextractS3Buckets', payload)
      this.$emit('markLoadingStatus', false)
      if (response && response?.buckets) {
        const buckets = []
        await response.buckets.forEach(bucket => buckets.push({ value: bucket, displayName: bucket }))
        this.fieldList.awsS3BucketList = buckets
        if (this.fieldList.awsS3BucketList.length)
          this.isAWSS3BucketListEmpty = false
      } else {
        this.isAWSS3BucketListEmpty = true
        this.snackbarTitle = "AWS Region"
        this.snackbarSubTitle = `There are no buckets associated with this region`
        this.snackbarImage = false
        this.showSnackbar = true
      }
    },
    isTheFormValid() {
      let validForm = Boolean(this.aipNameAWSConnectionEmpty && this.awsBucketDataIsPresent && this.textractAPISelected && ((this.objectFieldSelected && this.fieldsMapped) || this.detectSelected))
      if (this.formFieldsData.comprehend) {
        validForm = validForm && Boolean(this.formFieldsData.comprehendFieldID && this.formFieldsData.comprehendPIIData.length)
      }
      return validForm
    },
    objectChangeConfirmation() {
      this.formFieldsData.mappingData = {}
      this.deleteComprehendConfig()
      this.getObjectFields(this.formFieldsData.object.value)
      this.showPreventObjectChangeDialog = false
    },
    onObjectChanged(selectedItem) {
      this.emitWrapperIsDirty()
      if (isEmpty(this.lastSelectedObject) || isEmpty(this.formFieldsData.mappingData) || !this.formFieldsData.comprehend) {
        this.lastSelectedObject = selectedItem
        this.formFieldsData.object = selectedItem
        this.deleteComprehendConfig()
        this.getObjectFields(selectedItem.value)
        return
      }
      this.showPreventObjectChangeDialog = true
    },
    saveMappingData(mappingData) {
      if (mappingData) {
        this.formFieldsData.mappingData = mappingData
        this.formFieldsData.mappingData.processingOrder = Number(mappingData.processingOrder)
        this.isMappingDataExits = true
        this.formFieldsData.textractAIPName = mappingData.textractAIPName
        this.showDialogMappingPage = false
        this.formFieldsData.includeLineItemData = mappingData.includeLineItemData
        this.formFieldsData.lineItemDataFailureLogic = Number(mappingData.lineItemDataFailureLogic)
        this.emitWrapperIsDirty()
      }
    },
    setApiType() {
      if (this.detectSelected) {
        this.formFieldsData.ocr = true
      }
      if (this.formFieldsData.textractAPI.displayName === 'Lending') this.deleteComprehendConfig()
      this.emitWrapperIsDirty()
    },
    async setFormValues(data) {
      this.formFieldsData.awsAccessKeyID = data.AwsConfig.ClientID
      this.formFieldsData.awsSecretKey = data.AwsConfig.ClientSecret
      this.formFieldsData.textractAIPName = data.Name
      this.formFieldsData.id = data.Id
      this.isAWSAccountValid = true
      this.formFieldsData.ocr = data.Ocr
      this.formFieldsData.includeLineItemData = data.IncludeLineItemData
      this.formFieldsData.lineItemDataFailureLogic = String(data.LineItemDataFailureLogic)

      this.formFieldsData.awsRegion = this.fieldList.awsRegionList.find(item => item.value === data.AwsConfig.Region)
      this.fieldList.awsS3BucketList = [{ value: data.AwsConfig.BucketName, displayName: data.AwsConfig.BucketName }]
      this.formFieldsData.awsS3Bucket = this.fieldList.awsS3BucketList.find(item => item.value === data.AwsConfig.BucketName)
      this.isAWSS3BucketListEmpty = false
      this.s3BucketHaveAccess = true

      this.formFieldsData.textractAPI = this.fieldList.textractAPIList.find(item => item.value === data.TextractAPI)
      this.formFieldsData.object = this.fieldList.objectList.find(item => item.value === data.IndexFormId)
      this.lastSelectedObject = this.formFieldsData.object

      if (this.formFieldsData.object?.value) await this.getObjectFields(this.formFieldsData.object.value)
      this.formFieldsData.comprehend = data.Comprehend
      this.formFieldsData.comprehendAnnotationType = data.ComprehendAnnotationType
      this.formFieldsData.comprehendFieldID = this.indexFieldList.find(item => item.id === data.ComprehendFieldID)
      this.formFieldsData.comprehendPIIData = data.ComprehendPIIData.map(item => ({ value: item.Value, displayName: item.Value }))
      this.formFieldsData.burnRedactions = data.BurnRedactions

      this.formFieldsData.mappingData = {
        filename: data.Filename,
        originalSampleFilename: data.OriginalSampleFilename,
        mappings: data.Mappings,
        sampleData: data.SampleData,
        processingOrder: String(data.ProcessingOrder),
      }

      this.formFieldsData.pageCount = data.pageCount
    },
    async validateAwsCredentials() {
      this.$emit('markLoadingStatus', true)
      const payload = {
        ...(this.isEdit && {"id": this.currentData.Id}),
        "awsAccessKeyID": this.formFieldsData.awsAccessKeyID,
        "awsSecretKey": this.formFieldsData.awsSecretKey,
      }
      const response = await this.$store.dispatch('admin/validateTextractConnection', payload)
      this.$emit('markLoadingStatus', false)
      if (response && response?.Value === "True") {
        this.isAWSAccountValid = true
        this.snackbarTitle = "AWS Connection"
        this.snackbarSubTitle = `AWS account successfully validated`
        this.snackbarImage = true
        this.showSnackbar = true
      } else {
        this.isAWSAccountValid = false
        this.snackbarTitle = "AWS Connection"
        this.snackbarSubTitle = `Invalid AWS account`
        this.snackbarImage = false
        this.showSnackbar = true
      }
    },
    async validateAwsComprehendAccess() {
      this.$emit('markLoadingStatus', true)
      const payload = {
        ...(this.isEdit && {"id": this.currentData.Id}),
        "awsAccessKeyID": this.formFieldsData.awsAccessKeyID,
        "awsSecretKey": this.formFieldsData.awsSecretKey,
        "awsRegion": this.formFieldsData.awsRegion.value,
      }
      const response = await this.$store.dispatch('admin/testComprehendConnection', payload)
      this.$emit('markLoadingStatus', false)
      if (response && response?.Value === "True") {
        this.s3BucketHaveAccess = true
        this.snackbarTitle = "AWS Status"
        this.snackbarSubTitle = `Amazon Comprehend Access Verified Successfully`
        this.snackbarImage = true
        this.showSnackbar = true
      } else {
        this.s3BucketHaveAccess = false
        this.snackbarTitle = "AWS Status"
        this.snackbarSubTitle = `No access to Amazon Comprehend`
        this.snackbarImage = false
        this.showSnackbar = true
      }
    },
    async validateS3BucketAccess() {
      this.$emit('markLoadingStatus', true)
      const payload = {
        ...(this.isEdit && {"id": this.currentData.Id}),
        "awsAccessKeyID": this.formFieldsData.awsAccessKeyID,
        "awsSecretKey": this.formFieldsData.awsSecretKey,
        "awsRegion": this.formFieldsData.awsRegion.value,
        "awsS3Bucket": this.formFieldsData.awsS3Bucket.value,
      }
      const response = await this.$store.dispatch('admin/testTextractConnection', payload)
      this.$emit('markLoadingStatus', false)
      if (response && response?.Value === "True") {
        this.s3BucketHaveAccess = true
        this.snackbarTitle = "AWS Status"
        this.snackbarSubTitle = `AWS Account Settings Verified Successfully`
        this.snackbarImage = true
        this.showSnackbar = true
      } else {
        this.s3BucketHaveAccess = false
        this.snackbarTitle = "AWS Status"
        this.snackbarSubTitle = `Invalid AWS Account Settings`
        this.snackbarImage = false
        this.showSnackbar = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

div.form-row {
  padding: 6px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.btn-margin-fix {}

::v-deep .btn-margin-fix button {
  margin-left: 0px;
}

@media (min-width: $breakpoint-xl) {
  .btn-margin-fix {
    position: relative;
    top: 17px;
  }
}
.dialog-fullscreen {
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  transform: none;
}

.row-checkbox {
  margin-top: 30px;
}

.mid-checkbox {
  margin-top: 15px;
}

.container {
  width: 100%;
  padding-inline: 6px;
}

.checkbox {
  width: fit-content;
}

.checkbox ::v-deep svg {
  margin-right: 6px;
}

::v-deep .btn-margin-fix button {
  margin-left: 0px;
}

::v-deep .md-radio-label {
  font-weight: normal;
  color: #95989a;
}

.px-0 {
  padding-inline: 0 !important;
}

::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
  color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
  border-color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
  background-color: $secondary !important;
}
</style>
